import React, { FunctionComponent } from "react"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"
import Subnavigation from "../components/Subnavigation.tsx"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import Layout from "../components/layout"
import { Button } from "../components/Button.tsx"
import { isMobile } from "react-device-detect"
import SEO from "../components/Seo.js"

import { ITabItem } from "../types"

import { Tabs as ReactTabs, TabList, Tab, TabPanel } from "react-tabs"
import "../components/Tabs.scss"
import Listing from "../components/Listing.scss"

let DESCRIPTION = {}
let SUBTITLE = {}
let TITLE = {}
let SEO_TITLE = {}
let SEO_META = {}
let BACKGROUND_IMAGE = {}
let SUBMENU = []
let YEAR = {}
let MONTH = {}
let DAY = {}
const PERMALINK = "/careers/"
let CAREERS_ALL = []
let CAREERS_AU = []
let CAREERS_HK = []

const Tabs = ({ items }) => {
  return (
    <ReactTabs>
      <TabList>
        {items.map((item, index) => (
          <Tab key={index}>{item.title}</Tab>
        ))}
      </TabList>
      {items.map((item, index) => (
        <TabPanel key={index}>
          {item.title === "All" && (
            <div>
              <div id="expr3ss-jobs-widget" />
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    'if(typeof(Expr3ss)==="undefined"){var Expr3ss = [];}Expr3ss.Widget = {"config":{"token":"c7ad95b81fb0d6153bd999942f3f1533","parent":"body","id":"expr3ss-jobs-widget","sort":"division:asc,status:asc","grouping":"division","button":{"label":["Apply Now","Interest Only"],"colour":["#85cf9b","#141414","#ffffff"]},"collapsed":false,"noDescription":false,"hideInterest":false,"hideApply":false,"sourceTag":"6"}}; ',
                }}
              />
            </div>
          )}

          {item.title === "Australia" && (
            <div>
              <div id="expr3ss-jobs-widget" />
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    'if(typeof(Expr3ss)==="undefined"){var Expr3ss = [];}Expr3ss.Widget = {"config":{"token":"c7ad95b81fb0d6153bd999942f3f1533","parent":"body","id":"expr3ss-jobs-widget","sort":"division:asc,status:asc","grouping":"division","button":{"label":["Apply Now","Interest Only"],"colour":["#85cf9b","#141414","#ffffff"]},"collapsed":false,"noDescription":false,"hideInterest":false,"hideApply":false,"sourceTag":"6","divisions":"t9-gE9sZ-w~~"}}; ',
                }}
              />
            </div>
          )}

          {item.title === "Hong Kong" && (
            <div>
              <div id="expr3ss-jobs-widget" />
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    'if(typeof(Expr3ss)==="undefined"){var Expr3ss = [];}Expr3ss.Widget = {"config":{"token":"c7ad95b81fb0d6153bd999942f3f1533","parent":"body","id":"expr3ss-jobs-widget","sort":"division:asc,status:asc","grouping":"division","button":{"label":["Apply Now","Interest Only"],"colour":["#85cf9b","#141414","#ffffff"]},"collapsed":false,"noDescription":false,"hideInterest":false,"hideApply":false,"sourceTag":"6","divisions":"sw~~"}}; ',
                }}
              />
            </div>
          )}

          {item.content.map((i, index) => (
            <CareerBlock item={i} key={index} />
          ))}
        </TabPanel>
      ))}
    </ReactTabs>
  )
}

const CareerBlock = ({ item }) => {
  return (
    <div key={item.slug} className="item">
      <dt>
        <span className="year">{item.year}</span>
        <span className="day">{item.day}</span>
        <span className="month">{item.month}</span>
      </dt>
      <dd className="post">
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: item.title }} />
          <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
          <p className="buttons">
            <Button
              size="small"
              onClick={() =>
                item.slug ? (window.location.href = item.slug) : null
              }
            >
              {"Read More"}
            </Button>
            <a
              className="btn small dark"
              href="mailto:hr@ovologroup.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Apply Now"}
            </a>
          </p>
        </div>
      </dd>
    </div>
  )
}

const Blog = () => (
  <StaticQuery
    query={graphql`
      query BlogAllCareersQuery {
        allWordpressWpCareers(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD/MMM/YYYY")
              title
              slug
              link
              excerpt
              acf {
                location
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "about" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              options {
                description_careers
                background_image_careers {
                  source_url
                }
                subtitle_careers
                title_careers
                seo_title
                seo_meta_description
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          DESCRIPTION = node.options.description_careers
          TITLE = node.options.title_careers
          SUBTITLE = node.options.subtitle_careers
          BACKGROUND_IMAGE = node.options.background_image_careers.source_url
        })}
        {data.submenu.edges.map(({ node }, i) => {
          SUBMENU = node.items
        })}
        {data.allWordpressWpCareers.edges.map(({ node }, i) => {
          let [DAY, MONTH, YEAR] = node.date.split("/")
          node.day = DAY
          node.month = MONTH
          node.year = YEAR
        })}

        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          DESCRIPTION = node.options.description_careers
          TITLE = node.options.title_careers
          SEO_TITLE = node.options.seo_title
          SEO_META = node.options.seo_meta_description
          SUBTITLE = node.options.subtitle_careers
          BACKGROUND_IMAGE = node.options.background_image_careers.source_url
        })}
        <SEO title={SEO_TITLE} description={SEO_META} pathname={"/careers/"} />

        {data.submenu.edges.map(({ node }, i) => {
          SUBMENU = node.items
        })}

        <Sticky backgroundImage={BACKGROUND_IMAGE}>
          <Subnavigation items={SUBMENU} />

          <Title title={TITLE} subtitle={SUBTITLE} paragraphs={DESCRIPTION} />
          <div className="tabs">
            <div>
              <div id="expr3ss-jobs-widget" />
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    'if(typeof(Expr3ss)==="undefined"){var Expr3ss = [];}Expr3ss.Widget = {"config":{"token":"c7ad95b81fb0d6153bd999942f3f1533","parent":"body","id":"expr3ss-jobs-widget","sort":"division:asc,status:asc","grouping":"division","button":{"label":["Apply Now","Interest Only"],"colour":["#85cf9b","#141414","#ffffff"]},"collapsed":false,"noDescription":false,"hideInterest":false,"hideApply":false,"sourceTag":"6"}}; ',
                }}
              />
            </div>
          </div>
          <div className="center post">
            <h4 className="ovolo">Our Vision:</h4>
            <img
              className="center"
              src="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/shiny-happy-people-1.png"
              alt="Shiny happy people"
            />
          </div>
          <script
            type="text/javascript"
            src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"
          />
          <script
            type="text/javascript"
            src="https://developers.expr3ss.com/Files/js/widget.min.js"
          />
        </Sticky>
      </Layout>
    )}
  />
)

export default Blog
